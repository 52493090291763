export const PATH_NAME = {
  // General routes
  ROOT: '/',
  ERROR_404: '/404',
  ERROR_403: '/403',
  TERM_CONDITION: '/terms-condition',
  PRIVACY_POLICY: '/privacy-policy',
  Contact_Us: '/contact-us',
  FAQ: '/faq',
  // Login routes
  PASSWORD_RESET: '/update-password',
  LOGIN: '/login',
  ADMIN_LOGIN: '/admin/login',
  REGISTER: '/register',

  //logout
  LOGOUT: '/logout',

  // Cart routes
  PROFILE: '/profile',
  MYTICKET: '/my-ticket/:ticketId',

  // Reward routes
  REWARDS: '/rewards',
  REWARDSBYCATEGORY: '/rewards/:categoryName',
  REWARD_DETAILS: '/rewards/:category/:rewardTitle',

  // Offers routes
  OFFERS: '/manage-rewards/offers',
  UPDATE_OFFERS: '/manage-rewards/offers/update',

  // Cart routes
  CART: '/cart',

  // Checkout routes
  CHECKOUT: '/checkout',

  //Dashboard
  DASHBOARD: '/dashboard',

  //Manage Reward Category
  MANAGE_REWARD_CATEGORY: '/dashboard/rewards-category',
  ADD_REWARD_CATEGORY: '/dashboard/rewards-category/add',
  UPDATE_REWARD_CATEGORY: '/dashboard/rewards-category/update',

  //Manage Orders
  MANAGE_ORDERS: '/dashboard/manage-orders',

  //Manage Customer
  MANAGE_CUSTOMERS: '/dashboard/manage-customers',
  ADD_CUSTOMER: '/dashboard/manage-customers/add',
  UPDATE_CUSTOMER: '/dashboard/manage-customers/update',
  CUSTOMERS_USER: '/dashboard/manage-customers/users',
  USER_DETAILS: '/dashboard/manage-customers/view',

  //Manage Campaign
  MANAGE_CAMPAIGNS: '/dashboard/manage-campaigns',
  ADD_CAMPAIGN: '/dashboard/manage-campaigns/add',
  UPDATE_CAMPAIGN: '/dashboard/manage-campaigns/update',
  SET_REWARDS: '/dashboard/manage-campaigns/set-rewards',

  //Manage Campaign
  MANAGE_REWARDS: '/dashboard/manage-rewards',
  ADD_REWARDS: '/dashboard/manage-rewards/add',
  UPDATE_REWARDS: '/dashboard/manage-rewards/update',

  //Signup Code
  MANAGE_CODES: '/dashboard/manage-codes',
  //RedemptionCode
  REDEMPTION_CODE: '/dashboard/redemption-code',
  //RedemptionCode
  DINING_CODE: '/dashboard/dining-code',

  // Manage Mails
  MANAGE_MAIL_TEMPLATES: '/dashboard/setting/manage-template',
  ADD_MAIL_TEMPLATES: '/dashboard/setting/manage-template/add',
  UPDATE_MAIL_TEMPLATES: '/dashboard/setting/manage-template/:id',

  //ROLES - USERListing
  USER_LISTING: '/dashboard/setting/manage-user/users',
  ADD_USER: '/dashboard/setting/manage-user/users/add',
  UPDATE_USER: '/dashboard/setting/manage-user/:id',

  //Terms & condition
  ADD_TERMS: '/dashboard/terms/add',
  TERMS: '/dashboard/terms',
  UPDATE_TERMS: '/dashboard/terms/update',
  // Manage Privacy Policy
  ADD_PRIVACY_POLICY: '/dashboard/manage-privacy-policy/add',
  MANAGE_PRIVACY_POLICY: '/dashboard/manage-privacy-policy',
  UPDATE_PRIVACY_POLICY: '/dashboard/manage-privacy-policy/update',

  //Manage Permission
  MANAGE_PERMISSION: '/dashboard/permission',
  ADD_PERMISSION: '/dashboard/permission/add',
  UPDATE_PERMISSION: '/dashboard/permission/update',

  // USER_LISTING: '/dashboard/setting/manage-user/users',
  // UPDATE_USER: '/dashboard/setting/manage-user/:id',
};
