import axios from 'axios';
import api from './api';

function getAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken;
}
export const API_ENDPOINT = process.env.REACT_APP_ENDPOINT_URL;

export const createCustomer = async (formState: any) => {
  try {
    return api.post('/customers', formState);
  } catch (error) {
    return error;
  }
};
export const getCheckoutUrl = async (body: any) => {
  return api.post(`/payment/checkout`, body);
};

export const getCustomersList = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post('/user/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getPromotionalCodes = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post('user_code/get_listing_codes', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getRedemptionCodes = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post('user_code/get_redemption_codes', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getDiningCodes = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post('user_code/get_dinner_codes', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getOrdersList = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post('/orders/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserOrders = async ({ itemsPerPage, page, searchTerms, selectedCustomerId }: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms, id: selectedCustomerId };
  try {
    const response = await api.post('/orders/user_orders_by_id', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomersUsersList = async ({ itemsPerPage, page, customer_id, searchTerms }: any) => {
  const body = { limit: itemsPerPage, skip: page, customer_id, search: searchTerms };
  return api
    .post('customers/users_list', body)
    .then((response) => {
      return response;
      // Handle the successful response here
    })
    .catch((error) => {
      return error;
    });
};
//sync customer rewards
export const syncCustomerRewards = async ({ id, api_key }: any) => {
  const body = { id, api_key };
  try {
    const response = await api.post('/rewards/sync_customer_offers', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const completeUserProfile = async ({ itemsPerPage, page }: any) => {
  const body = { limit: itemsPerPage, skip: page };
  try {
    const response = await api.post('/customers/users', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomerById = async (id: any) => {
  try {
    const response = await api.get(`/customers/profile/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const checkout = async (priceId: any, stripeCustomerId: any, quantity: any, price: number) => {
  try {
    const response = await api.post(`/payment/checkout`, { priceId, stripeCustomerId, quantity, price });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCustomer = async (data: any) => {
  try {
    const payload = { ...data };

    // Check if data.password is a valid non-empty string
    if (data.password && typeof data.password === 'string' && data.password.trim() !== '') {
      payload.password = data.password; // Include password in the payload
    } else {
      // If data.password is not a valid string, remove it from the payload
      delete payload.password;
    }

    const response = await api.patch(`/customers/profile/${data.id}`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteCustomer = async (id: any) => {
  try {
    const response = await api.delete(`/customers/profile/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCustomerLists = async () => {
  try {
    const response = await api.post('/customers/get_all_customers', {});
    return response;
  } catch (error) {
    return error;
  }
};
export const submitContactUs = async (customerData: any) => {
  try {
    const response = await api.post('/contact-us', customerData);
    return response;
  } catch (error) {
    return error;
  }
};
export const customerPasswordUpdate = async (data: any) => {
  try {
    const response = await api.post('/customers/password-update', data);
    return response;
  } catch (error) {
    return error;
  }
};
export const adminPasswordUpdate = async (data: any) => {
  try {
    const response = await api.post('/user/password-update', data);
    return response;
  } catch (error) {
    return error;
  }
};

// Campaign API start from here

export const getCampaignList = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchTerms,
  };
  try {
    const response = await api.post('/campaigns/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCutomerCampaign = async ({ customer }: any) => {
  const body = {
    customer: customer,
  };
  try {
    const response = await api.post('/campaigns/get_customer_campaigns', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const createCampaign = async (data: any) => {
  try {
    const response = await api.post('/campaigns', data);
    return response;
  } catch (error) {
    return error;
  }
};
export const resendCustomerTickets = async (id: any) => {
  try {
    const response = await api.post('/payment/send_order_confirmation_email', id);
    return response;
  } catch (error) {
    return error;
  }
};

export const getProductInformation = async ({ price_id }: any) => {
  try {
    const response = await api.post('/payment/get_product', { price_id });
    return response;
  } catch (error) {
    return error;
  }
};

export const createTemplate = async (data: any) => {
  try {
    const response = await api.post('/email-template', data);
    return response;
  } catch (error) {
    return error;
  }
};
export const getEmailTemplatesList = async ({ itemsPerPage, page, searchKeywords }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchKeywords,
  };
  try {
    const response = await api.post('/email-template/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteTemplate = async (body: any) => {
  try {
    const response = await api.delete(`/email-template/${body}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getTemplateById = async (id: any) => {
  try {
    const response = await api.get(`/email-template/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateTemplate = async (body: any) => {
  try {
    const response = await api.patch(`/email-template/${body.id}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const createUser = async (formState: any) => {
  try {
    const response = await api.post('/user', formState);
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserList = async ({ itemsPerPage, page }: any) => {
  const body = { limit: itemsPerPage, skip: page };
  try {
    const response = await api.post('/user/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteUser = async (body: any) => {
  try {
    const response = await api.delete(`/user/${body}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserProfile = async () => {
  try {
    const response = await api.get(`/user/profile`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserById = async (id: any) => {
  try {
    const response = await api.get(`/user/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateUser = async (body: any) => {
  try {
    const response = await api.patch(`/user/${body.id}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCamapaginImages = async (data: any) => {
  const formData = new FormData();
  formData.append('campaign_id', data.campaign_id);
  formData.append('image', data.image);

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(process.env.REACT_APP_ENDPOINT_URL + `/campaigns/set_images`, formData, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCampaignById = async (id: any) => {
  try {
    const response = await api.get(`/campaigns/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPdfByName = async (name: any) => {
  try {
    const response = await api.get(`/pdf/${name}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCampaign = async (body: any) => {
  try {
    const response = await api.patch(`/campaigns/${body.id}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCampaign = async (body: any) => {
  try {
    const response = await api.delete(`/campaigns/${body}`);
    return response;
  } catch (error) {
    return error;
  }
};

// Rewards API start from here

export const getRewardsList = async ({ itemsPerPage, page, search, customer_id }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: search,
    customer_id: Number(customer_id),
  };
  try {
    const response = await api.post('/rewards/find_customer_rewards', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRewardById = async (id: any) => {
  try {
    const response = await api.get(`/rewards/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getOfferList = async (id: any, page: number, searchTerms: string) => {
  const body = {
    reward_id: id,
    skip: page,
    limit: 10,
    search: searchTerms,
  };
  try {
    const response = await api.post(`/offers/get_listing_reward_offers`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getOfferById = async (id: any) => {
  try {
    const response = await api.get(`/offers/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateOffer = async (body: any) => {
  try {
    const response = await api.patch(`/offers/${body.offerId}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateReward = async (data: any) => {
  try {
    const response = await api.patch(`/rewards/${data.rid}`, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateRewardImages = async (data: any) => {
  const formData = new FormData();
  formData.append('rid', data.id);
  formData.append('type', data.type);
  formData.append('image', data.image);

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(process.env.REACT_APP_ENDPOINT_URL + `/rewards/set_images`, formData, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateRewardCategoryImages = async (data: any) => {
  const formData = new FormData();
  formData.append('id', data.id);
  formData.append('image', data.image);
  formData.append('type', data.type);

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(process.env.REACT_APP_ENDPOINT_URL + `/reward-category/set_images`, formData, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteReward = async (body: any) => {
  try {
    const response = await api.delete(`/rewards/${body}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllRewards = async (body: any) => {
  try {
    const response = await api.post('/rewards/get_all_rewards', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const setRewards = async (data: any) => {
  try {
    const response = await api.post('/campaigns/set_rewards', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const syncRewards = async () => {
  const data = {};
  try {
    const response = await api.post('/rewards/sync_rewards', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRewardsCategory = async () => {
  const data = {};
  try {
    const response = await api.post('/reward-category/get_all_cats', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllRewardsCategory = async ({ itemsPerPage, page, searchTerms }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchTerms,
  };
  try {
    const response = await api.post('/reward-category/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const addRewardsCategory = async (body: any) => {
  try {
    const response = await api.post('/reward-category', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRewardCategoryById = async (id: any) => {
  try {
    const response = await api.get(`/reward-category/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateRewardCategoryById = async (body: any) => {
  try {
    const response = await api.patch(`/reward-category/${body.id}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomerRewardCategory = async (id: any, campaign: any) => {
  try {
    const response = await api.post(`/customers/reward-category`, { customer_id: id, campaign });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomerRewardsByCategory = async (rewardCategory: any) => {
  // Make an API call using your custom Axios instance
  return api
    .post('/rewards/get_category_rewards', { category_name: rewardCategory, category: rewardCategory })
    .then((response) => {
      return response;
      // Handle the successful response here
    })
    .catch((error) => {
      return error;
    });
};

export const redeemRewards = async (data: any) => {
  try {
    const response = await api.post(`/redemption`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const UnsetCustomerReward = async (id: any) => {
  try {
    const response = await api.post(`/customers/unset_reward`, { id: id });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllOrders = async (id: any) => {
  try {
    const response = await api.post(`/redemption/get_my_orders`, { user_id: id });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCampaignRewards = async (data: any) => {
  try {
    const response = await api.post(`/customers/get_campaign_rewards`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllUsersRedeemedRewards = async (data: any) => {
  return api
    .post('/redemption/user_reward_history', data)
    .then((response) => {
      return response;
      // Handle the successful response here
    })
    .catch((error) => {
      return error;
    });
};

export const getIndividualCustomerOrders = async (data: any) => {
  return api
    .post('/orders/user_orders_by_id', data)
    .then((response) => {
      return response;
      // Handle the successful response here
    })
    .catch((error) => {
      return error;
    });
};

// localtion api

export const getAllLocations = async () => {
  const body = {};
  try {
    const response = await api.post('/locations/get_all', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllTags = async () => {
  const body = {};
  try {
    const response = await api.post('/tags/get_all', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getOfferByZip = async (body: any) => {
  try {
    return api.post('/offers/get_location_offers', body);
  } catch (error) {
    return error;
  }
};

export const getAllPartners = async () => {
  const body = {};
  try {
    const response = await api.post('/partners/get_all', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadRegistrationCodeUsingCsv = async (csvFile: any | null) => {
  const formData = new FormData();
  // Check if a CSV file is provided
  if (csvFile) {
    formData.append('file', csvFile);
  }

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await axios.post(process.env.REACT_APP_ENDPOINT_URL + `/user_code/set_offer_codes`, formData, { headers });
    return response;
  } catch (error) {
    return error;
  }
};
export const uploadRedemptionCodeUsingCsv = async (csvFile: any | null) => {
  const formData = new FormData();
  // Check if a CSV file is provided
  if (csvFile) {
    // Assuming you want to upload a CSV file
    formData.append('file', csvFile);
  }

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await axios.post(process.env.REACT_APP_ENDPOINT_URL + `/user_code/set_redemption_codes`, formData, {
      headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadDiningCodeUsingCsv = async (csvFile: any | null) => {
  const formData = new FormData();
  // Check if a CSV file is provided
  if (csvFile) {
    // Assuming you want to upload a CSV file
    formData.append('file', csvFile);
  }

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await axios.post(process.env.REACT_APP_ENDPOINT_URL + `/user_code/set_dinner_codes`, formData, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

export const getRedemptionCode = async ({ itemsPerPage, page, id }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    reward_id: id,
  };

  try {
    return await api.post('/offers/get_listing_codes', body);
  } catch (error) {
    return error;
  }
};

export const deleteRedemptionCode = async (body: any) => {
  try {
    return await api.post('/offers/remove_offer_codes', body);
  } catch (error) {
    return error;
  }
};

export const deleteUnusedCode = async (offer_id: any) => {
  try {
    return await api.post('/offers/remove_unused_offer_codes', { offer_id });
  } catch (error) {
    return error;
  }
};

export const getSingleCode = async (id: any) => {
  try {
    return await api.get(`/offers/get_offer_code/${id}`);
  } catch (error) {
    return error;
  }
};

export const updateOfferCode = async (body: any) => {
  try {
    return await api.patch(`/offers/update_offer_code/${body.id}`, body);
  } catch (error) {
    return error;
  }
};

export const getTermAndConditions = async ({ itemsPerPage, page, searchKeywords }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchKeywords,
  };
  try {
    const response = await api.post('/terms/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};

export const createTerms = async (body: any) => {
  try {
    return await api.post(`/terms`, body);
  } catch (error) {
    return error;
  }
};
export const deleteTerms = async (id: any) => {
  try {
    return await api.delete(`/terms/${id}`);
  } catch (error) {
    return error;
  }
};

export const getSingleTerms = async (id: any) => {
  try {
    return await api.get(`/terms/${id}`);
  } catch (error) {
    return error;
  }
};

export const updateTerms = async (body: any) => {
  try {
    return await api.patch(`/terms/${body.id}`, body);
  } catch (error) {
    return error;
  }
};
export const getPrivacyPolicies = async ({ itemsPerPage, page, searchKeywords }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchKeywords,
  };
  try {
    const response = await api.post('/privacy-policy/get_listing', body);
    return response;
  } catch (error) {
    return error;
  }
};
export const createPrivacyPolicy = async (body: any) => {
  try {
    return await api.post(`/privacy-policy`, body);
  } catch (error) {
    return error;
  }
};
export const deletePrivacyPolicy = async (id: any) => {
  try {
    return await api.delete(`/privacy-policy/${id}`);
  } catch (error) {
    return error;
  }
};

export const getSinglePrivacyPolicy = async (id: any) => {
  try {
    return await api.get(`/privacy-policy/${id}`);
  } catch (error) {
    return error;
  }
};
export const getPrivacyPolicyFromCampaign = async (body: any) => {
  try {
    return await api.post(`/privacy-policy/get_privacy_policy`, body);
  } catch (error) {
    return error;
  }
};
export const getTermFromCampaign = async (body: any) => {
  try {
    return await api.post(`/terms/get_terms`, body);
  } catch (error) {
    return error;
  }
};

export const updatePrivacyPolicy = async (body: any) => {
  try {
    return await api.patch(`/privacy-policy/${body.id}`, body);
  } catch (error) {
    return error;
  }
};

export const getAllLanguage = async () => {
  try {
    return await api.get(`/languages`);
  } catch (error) {
    return error;
  }
};

export const getAllRoles = async () => {
  try {
    return await api.get(`/permissions/get_roles`);
  } catch (error) {
    return error;
  }
};

export const getAllMoudles = async () => {
  try {
    return await api.get(`/permissions/get_modules`);
  } catch (error) {
    return error;
  }
};

//permissions
export const getPermissionList = async () => {
  try {
    return await api.get('/permissions');
  } catch (error) {
    return error;
  }
};
export const updatePermissionList = async (data: any) => {
  try {
    return await api.post('permissions/update_permission', data);
  } catch (error) {
    return error;
  }
};
