import React from 'react';
import { Divider } from '@mui/material';
import './styles.scss';
import { LogogWhite } from 'configs/logo';
import Login from 'pages/Login/Login';
import { PATH_NAME } from 'configs/pathName';
import { useSelector } from 'react-redux';
import Brands from 'components/Brands';

function Footer() {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  return (
    <React.Fragment>
      {/* <Brands /> */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <ul className="quick-links">
                <li>
                  <a href={PATH_NAME.TERM_CONDITION}>TERMS AND CONDITIONS</a>
                </li>
                <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.PRIVACY_POLICY}>PRIVACY AND COOKIES POLICY</a>
                </li>
                <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.Contact_Us}>CONTACT Us</a>
                </li>
                <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.FAQ}>FAQS</a>
                </li>
                {/* <Divider orientation="vertical" className="divider" />
                <li>
                  <a href={PATH_NAME.FAQ}>TELL ME MORE</a>
                </li> */}
              </ul>
              <div className="logo">
                <LogogWhite width={325} height={107} />
              </div>

              <div className="copy-right">
                <div className="copyrightText">{'© 2024 The Coca-Cola Company. © 2024 AMC'}</div>
                <div className="poweredby">Powered by TLC Rewards</div>
              </div>
              <div className="text-left text-white">
                <div>
                  <p>
                    Limit 10 per person per transaction. No Cash Value. Not for resale. No refund. Good at any AMC Theaters®
                    location in the United States only. These tickets exclude opening night fan events, double features, marathon
                    events, alternative content, certain foreign language titles and special theatrical presentations. Subject to
                    surcharge for 3D, premium large screen format (e.g., IMAX®, Dolby®, AMC Prime®), film festivals, and premium
                    services; location services may also apply at select locations, including Disney and Universal properties
                    operated by AMC.
                  </p>
                  <p>
                    AMC reserves the right to change these terms and conditions without notice, including changes and additions to
                    the surcharge fees, restrictions or exclusions
                  </p>
                  <p>
                    AMC Black Tickets are exchange items that are NOT eligible toward earning AMC Stubs rewards either at time of
                    purchase or time of redemption. Valid seven days a week.
                  </p>
                  <p>
                    Dining credit terms and conditions apply here:
                    <br />
                    <a
                      href="https://memberservices.entertainment.com/KnowledgeBase/Details/?id=145-ENTERTAINMENT%C2%AE-MEMBERSHIPS,-PRODUCTS,-AND-WEBSITE-Rules,-Terms,-and-Conditions-of-Use"
                      target="_blank"
                      style={{ color: 'white', wordWrap: 'break-word' }}
                    >
                      https://memberservices.entertainment.com/KnowledgeBase/Details/?id=145-ENTERTAINMENT%C2%AE-MEMBERSHIPS,-PRODUCTS,-AND-WEBSITE-Rules,-Terms,-and-Conditions-of-Use
                    </a>
                    . Credit card required. Must be 18 years or older. US only. Full value of the reward may not be able to be
                    redeemed in a single transaction. Only valid at participating locations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
