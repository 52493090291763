import { Drawer, Button, IconButton } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { handleCartList, removeFromCart } from 'redux/actions/auth.action';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IMAGES_PATH } from 'configs/imagesPath';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { PATH_NAME } from 'configs/pathName';

function CartDrawer({ isCartOpen, cartItems = [] }: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const calculateAmount = (cartItems: any) => {
    let amount = 0;
    cartItems.forEach((item: any) => {
      const singleItemPrice = item.cartCount * item.price;
      amount += singleItemPrice;
    });
    const finalAmount = amount.toFixed(2);
    return finalAmount;
  };
  const handleCheckoutClick = () => {
    history.push(PATH_NAME.CART);
    dispatch(handleCartList(false));
  };

  return (
    <Drawer className="cart-wrapper" anchor="right" open={isCartOpen} onClose={() => dispatch(handleCartList(false))}>
      <div className="content">
        <div className="cart-header">
          <div className="title">Cart</div>
          <IconButton aria-label="close" onClick={() => dispatch(handleCartList(false))}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="cart-body">
          {cartItems.length ? (
            cartItems.map((item: any, index: number) => {
              return (
                <div className="item-list" key={index}>
                  <div className="d-flex align-items-center col-11">
                    <div className="reward-logo">
                      <div className="logo-wrap">
                        <img src={item.logoUrl || IMAGES_PATH.logo.logo} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                    <div className="reward-intro">
                      <div className="rew-title">{item.name}</div>
                      {item.description ? (
                        <div
                          className="rew-desc"
                          dangerouslySetInnerHTML={{
                            __html: item.description.length > 20 ? item.description.slice(0, 50) + '...' : item.description,
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </div>
                  <div className="action d-flex align-items-center flex-column col-1">
                    <div className="price">{item.cartCount}</div>
                    <div className="delete mt-auto ms-lg-auto">
                      <IconButton aria-label="close" onClick={() => dispatch(removeFromCart(item.id))}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center mt-5">Your cart is empty</p>
          )}
        </div>
        <div className="cart-footer">
          {cartItems.length ? (
            <div className="d-flex justify-content-between align-content-center">
              <div className="label">Total Amount</div>
              <div className="total-price">${calculateAmount(cartItems)}</div>
            </div>
          ) : (
            ''
          )}
          {/* <div className="d-flex justify-content-between align-content-center">
            <div className="label">Remaining Credit</div>
            <div className="total-price">{remainingBalance}</div>
          </div> */}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={cartItems.length ? 'pinkbutton' : ''}
            disabled={cartItems.length == 0}
            onClick={handleCheckoutClick}
          >
            Proceed To Checkout
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const mapStateToProps = (state: any) => ({
  isCartOpen: state.auth.isCartOpen,
  cartItems: state.auth.cartItems,
});

export default connect(mapStateToProps)(CartDrawer);
