import { IAppActionTypes, IAppActionCreator, IAppState } from 'models/IAppState';

const initialState: IAppState = {
  isLoading: false,
  rewardsList: [],
  rewardCategories: [],
  redeemedRewards: [],
  testing: [],
  language: [],
  activeCustomerinRewards: {},
};

const reducer = (state = initialState, { type, payload }: IAppActionCreator) => {
  switch (type) {
    case IAppActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case IAppActionTypes.SET_LANGUAGE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case IAppActionTypes.SET_LANGUAGE_SUCCESS: {
      return {
        ...state,
        language: payload,
        isLoading: false,
      };
    }
    case IAppActionTypes.SET_LANGUAGE_FAILURE: {
      return {
        ...state,
        language: [],
        isLoading: false,
      };
    }

    case IAppActionTypes.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
