import { USER_ROLE } from 'configs/userRole';

class AuthService {
  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken || !this.isValidToken(accessToken)) return;
    this.setSession('accessToken', accessToken);
  };

  loginWithAuth0 = async (username: string, roleUser: string, code: string, accessToken: string) => {
    if (accessToken) {
      this.setSession('accessToken', accessToken);
    }

    const user = {
      user: username,
      role: roleUser,
      code: code,
    };

    if (accessToken) {
      const userStringify = JSON.stringify(user);
      this.setSession('user', userStringify);
    }

    return user;
  };

  loginWithCustomerUserAuth0 = async (accessToken: string, role: string) => {
    if (accessToken) {
      this.setSession('accessToken', accessToken);
      this.setSession('role', role);
    }
  };

  loginWithToken = async () => {
    return {
      user: 'tonynguyen',
    };
  };

  setSession = (key: string, accessToken: string) => {
    localStorage.setItem(key, accessToken);
  };

  logOut = () => {
    localStorage.clear();
  };

  getUser = () => {
    const user = localStorage.getItem('user') || '';
    return user;
  };

  getAccessToken = () => localStorage.getItem('accessToken');
  getRole = () => localStorage.getItem('role');
  isAdmin = () => {
    const accessToken = this.getAccessToken();
    const role = this.getRole();
    if (accessToken && role == USER_ROLE.ADMIN) {
      return true;
    }
    return false;
  };
  isAuthenticated = () => !!this.getAccessToken();

  isValidToken = (accessToken: string | null) => {
    const expireTime = 1606275140.897;
    if (!accessToken) return false;

    const currentTime = Date.now() / 1000;

    return expireTime < currentTime;
  };
}

const authService = new AuthService();

export default authService;
