import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PATH_NAME } from 'configs/pathName';
import { IRoutes } from '../models/IRoutes';
import NavLayout, { EmptyNavLayout } from 'layouts/NavLayouts/NavLayout';
import { USER_ROLE } from 'configs/userRole';
import AdminAccess from 'guards/AdminGuard/AdminAccess';
import RoleRoute from './RoleRoute';
import AuthGuard from 'guards/AuthGuard';
import HomeSkeleton from 'layouts/SkeletonLoader/SkeletonLoader';
import AdminLayoutDecider from 'layouts/AdminLayoutDecider/AdminLayoutDecider';

//  Users routes
const Home = lazy(() => import('pages/Home/Home'));
const Register = lazy(() => import('pages/Register/Register'));
const CommingSoon = lazy(() => import('components/CommingSoon/CommingSoon'));
const Login = lazy(() => import('pages/Login/Login'));
const Cart = lazy(() => import('pages/Cart'));
const Checkout = lazy(() => import('pages/Checkout'));
const TermsCondition = lazy(() => import('pages/Terms-condition'));
const PrivacyPolicy = lazy(() => import('pages/Privacy-policy'));
const Faq = lazy(() => import('pages/Faq'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const MyTicket = lazy(() => import('pages/MyAccount/Ticket'));
const Logout = lazy(() => import('pages/Logout'));

//  dashboard routes
const Dashboard = lazy(() => import('pages/Dashboard'));
const customers = lazy(() => import('pages/Dashboard/Customers'));
const Orders = lazy(() => import('pages/Dashboard/Orders'));
const Codes = lazy(() => import('pages/Dashboard/Codes'));
const RedemptionCodes = lazy(() => import('pages/Dashboard/RedemptionCodes'));
const DiningCodes = lazy(() => import('pages/Dashboard/DiningCodes'));
const Profile = lazy(() => import('pages/MyAccount/Profile'));
const PasswordReset = lazy(() => import('pages/PasswordReset'));

const routesConfig: IRoutes[] = [
  {
    path: PATH_NAME.ROOT,
    layout: process.env.REACT_APP_SHOW_HOME ? NavLayout : EmptyNavLayout,
    routes: process.env.REACT_APP_SHOW_HOME
      ? [
          {
            exact: true,
            path: PATH_NAME.ROOT,
            guard: AuthGuard,
            component: Home,
          },
          {
            exact: true,
            path: PATH_NAME.REGISTER,
            component: Register,
          },
          {
            exact: true,
            path: PATH_NAME.LOGIN,
            component: Login,
          },
          {
            exact: true,
            path: PATH_NAME.PASSWORD_RESET,
            component: PasswordReset,
          },
          {
            exact: true,
            path: PATH_NAME.CART,
            component: Cart,
            guard: AuthGuard,
          },
          {
            exact: true,
            path: PATH_NAME.CHECKOUT,
            component: Checkout,
            guard: AuthGuard,
          },
          {
            exact: true,
            path: PATH_NAME.TERM_CONDITION,
            component: TermsCondition,
          },
          {
            exact: true,
            path: PATH_NAME.PRIVACY_POLICY,
            component: PrivacyPolicy,
          },
          {
            exact: true,
            path: PATH_NAME.FAQ,
            component: Faq,
          },
          {
            exact: true,
            path: PATH_NAME.Contact_Us,
            component: ContactUs,
          },
          {
            exact: true,
            path: PATH_NAME.PROFILE,
            component: Profile,
            guard: AuthGuard,
          },
          {
            exact: true,
            path: PATH_NAME.MYTICKET,
            component: MyTicket,
            guard: AuthGuard,
          },

          // Dashboard Routes start here
          {
            exact: true,
            path: PATH_NAME.DASHBOARD,
            component: Dashboard,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.LOGOUT,
            component: Logout,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.MANAGE_CUSTOMERS,
            component: customers,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.MANAGE_ORDERS,
            component: Orders,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.MANAGE_CODES,
            component: Codes,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.REDEMPTION_CODE,
            component: RedemptionCodes,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.DINING_CODE,
            component: DiningCodes,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
        ]
      : [
          {
            path: '*',
            component: CommingSoon,
          },
        ],
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: Home,
      },
      {
        component: () => <Redirect to={PATH_NAME.ROOT} />,
      },
    ],
  },
];

const renderRoutes = (routes: IRoutes[]) => {
  return (
    <>
      {routes ? (
        <Suspense fallback={<HomeSkeleton />}>
          <Switch>
            {routes.map((route: IRoutes, idx: number) => {
              const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const Component = route.component;
              const requireRoles = route.requireRoles || [];

              return (
                <Route
                  key={`routes-${idx}`}
                  path={route.path}
                  exact={route.exact}
                  render={(props: any) => (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <RoleRoute requireRoles={requireRoles}>
                            <Component {...props} />
                          </RoleRoute>
                        )}
                      </Layout>
                    </Guard>
                  )}
                />
              );
            })}
          </Switch>
        </Suspense>
      ) : null}
    </>
  );
};

function Routes() {
  return (
    <>
      <AdminLayoutDecider>{renderRoutes(routesConfig)}</AdminLayoutDecider>
    </>
  );
}

export default Routes;
