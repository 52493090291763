export const IMAGES_PATH = {
  logo: {
    logoBlue: '/assets/tlc/logo/tlc_group_logo.png',
    logo: '/assets/tlc/logo/logo.svg',
    logoWhite: '/assets/tlc/logo/logoWhite.svg',
    brandLogo: '/assets/tlc/logo/Logo_Cobranded.png',
  },
  icons: {
    bag: '/assets/tlc/icons/bag.svg',
    user: '/assets/tlc/icons/user.svg',
    togglerDark: '/assets/tlc/icons/toggler.svg',
    dashboard: '/assets/tlc/icons/dashboard.svg',
    wellness: '/assets/tlc/icons/wellness.svg',
    dining: '/assets/tlc/icons/dining.svg',
    activites: '/assets/tlc/icons/activities.svg',
    entertainment: '/assets/tlc/icons/entertainment.svg',
    travel: '/assets/tlc/icons/travel.svg',
    learning: '/assets/tlc/icons/learning.svg',
  },
  slider: {
    slide1: '/assets/tlc/banners/slider1.png',
    slide2: '/assets/tlc/banners/slider2.png',
  },
  background: {
    loginBackground: '/assets/tlc/backgrounds/loginBackground.jpg',
    homeBackground: '/assets/tlc/backgrounds/home_banner.jpg',
    privacyBackground: '/assets/tlc/backgrounds/legal-bnr.png',
    termsBackground: '/assets/tlc/backgrounds/legal-bnr.png',
    cartBackground: '/assets/tlc/backgrounds/cart_banner.jpg',
    signupBackground: '/assets/tlc/backgrounds/signupBackground.png',
    loginNewBackground: '/assets/tlc/backgrounds/loginBackground.png',
    listing: '/assets/tlc/backgrounds/listing.jpg',
    checkout: '/assets/tlc/backgrounds/checkout.jpg',
  },
  pageImage: {
    checkoutSuccess: '/assets/tlc/images/checkout-success.png',
  },
};
