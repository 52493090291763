import { Dispatch } from 'redux';

// types
import { IAuthActionTypes, INewUserActionTypes } from 'models/IAuthState';
import { IAppActionTypes } from 'models/IAppState';
import { IHistory } from 'models/ICommon';

// services
import authService from 'services/authService';
import httpRequest from 'services/httpRequest';

// configs
import { API_ENDPOINT } from 'apis/common.api';

import { handleScrollToTop } from 'utils/ScrollTop';
import { PATH_NAME } from 'configs/pathName';
import { USER_ROLE } from 'configs/userRole';
import AlertEmpty from 'components/Modals/AlertEmpty';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';

const enum API {
  CREATE_USER = '/user/',
  PAYMENT_CHECKOUT = '/payment/checkout/',
  ADMIN_LOGIN = '/user/login',
  USER_LOGIN = '/user/login',
  GET_PROFILE_DETAILS = '/user/profile',
  GET_SUBSCRITPIONS = '/payment/subscriptions',
  RESET_PASSWORD_ADMIN = '/user/password-reset',
  RESET_PASSWORD_CUSTOMER = '/user/password-reset',
  UPDATE_PASSWORD = '/user/password-update',
  VERIFYTOKEN = '/user/verify_two_fa',
  RESENDTOKEN = '/user/resend_code',
  LOGINWITHUSERID = '/customers/auth_customer_user',
  REDEEMREWARDS = '/redemption',
}

interface UserDataPayload {
  user: string;
  role: string;
  code: string;
}

// Define the action type for your setUserData action
interface SetUserDataAction {
  type: typeof IAuthActionTypes.SILENT_LOGIN;
  payload: UserDataPayload;
}

export const verifyTwoFaCode = (body: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.VERIFYTOKEN}`, body);
    if (data?.status == true) {
      const role = data.role;
      const accessToken = data.data[0]?.accessToken; // Use optional chaining to handle possible undefined
      if (!accessToken) {
        throw new Error('Failed to Login');
      }
      await authService.loginWithCustomerUserAuth0(accessToken, role);
      dispatch({ type: IAuthActionTypes.LOGIN_SUCCESS });
      history.push(PATH_NAME.DASHBOARD);
      dispatch({
        type: IAuthActionTypes.HANDLE_TWO_FA,
        payload: false,
      });
    } else {
      AlertEmpty({
        title: 'Invalid Verification Code',
        message: data?.message,
        button: 'OKAY',
      });
      // Handle invalid credentials
      dispatch({
        type: IAuthActionTypes.TWOFA_FAILURE,
        payload: {},
      });
    }
  } catch (error: any) {
    AlertEmpty({ title: 'Login Failed', message: error.message, button: 'Okay' });
    dispatch({
      type: IAuthActionTypes.LOGIN_FAILURE,
      payload: {
        error: 'An error occurred during login. Please try again later.', // Provide an error message
      },
    });
  }
};

export const loginUser = (email: string, password: string, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.LOGIN_REQUEST });
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.USER_LOGIN}`, { email, password });
    if (data.status) {
      const role = data.role;
      if (role === USER_ROLE.ADMIN) {
        if (data.data[0].two_fa_sent) {
          dispatch({
            type: IAuthActionTypes.TWOFA_REQUEST,
          });
        } else {
          dispatch({
            type: IAuthActionTypes.TWOFA_FAILURE,
          });
        }
      } else {
        const accessToken = data.data[0]?.accessToken; // Use optional chaining to handle possible undefined
        if (!accessToken) {
          throw new Error('Failed to Login');
        }
        await authService.loginWithCustomerUserAuth0(accessToken, role);
        dispatch({ type: IAuthActionTypes.LOGIN_SUCCESS });
        if (role == USER_ROLE.CUSTOMER) {
          history.push(PATH_NAME.ROOT);
        }
      }
    } else {
      dispatch({
        type: IAuthActionTypes.LOGIN_FAILURE,
      });
      AlertEmpty({
        title: 'Invalid Credentials',
        message: data?.message,
        button: 'OKAY',
      });
      history.push(PATH_NAME.ROOT);
    }
  } catch (error: any) {
    if (error?.reponse?.data?.message) {
      AlertEmpty({ title: 'Login Failed', message: error.response.data.message, button: 'Okay' });
    } else {
      AlertEmpty({ title: 'Login Failed', message: 'Failed to Login', button: 'Okay' });
    }
    authService.logOut();
    dispatch({
      type: IAuthActionTypes.LOGIN_FAILURE,
    });
    dispatch({ type: IAuthActionTypes.LOGOUT });
    handleScrollToTop();
  }
};

export const resend2FACode = (body: any) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await httpRequest.postReq(`${API_ENDPOINT}${API.RESENDTOKEN}`, body);
    if (response.data.status === true) {
      dispatch({
        type: IAuthActionTypes.RESEND_TWOFA_REQUEST,
        payload: { sent: true },
      });
      toast.success('Verification code sent.');
    } else {
      AlertEmpty({
        title: 'Unable to send verification code!',
        message: 'Please try agin later!',
        button: 'OKAY',
      });
      // Dispatch an action to handle the API error state if needed
      dispatch({
        type: IAuthActionTypes.RESEND_TWOFA_FAILURE,
        payload: {},
      });
    }
  } catch (error: any) {
    // Handle any network or request-related errors here
    AlertEmpty({
      title: 'Unable to send verification code!',
      message: 'Please try agin later!',
      button: 'OKAY',
    });
    // Dispatch an action to handle the error state if needed
    dispatch({
      type: IAuthActionTypes.RESEND_TWOFA_FAILURE,
      payload: {},
    });
  }
};

export const cancel2FAcode = () => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: IAuthActionTypes.TWOFA_FAILURE,
    payload: {},
  });
};

export const resetPasswordCustomer = (email: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.RESET_PASSWORD_REQUEST });
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.RESET_PASSWORD_CUSTOMER}`, email);
    dispatch({
      type: IAuthActionTypes.RESET_PASSWORD,
    });
    if (data?.status === true) {
      dispatch({ type: IAuthActionTypes.LOGOUT });
      history.push(PATH_NAME.ROOT);
      toast.success('Mail sent succesfully');
    } else {
      if (data?.message) {
        toast.error(data.message);
        return;
      }
      toast.error('Some Error Occured');
    }
  } catch (error: any) {
    dispatch({
      type: IAuthActionTypes.RESET_PASSWORD,
    });
    if (error?.response?.data?.message[0]) {
      toast.error(error.response.data.message[0]);
      return;
    }
    toast.error('Error while logging in');
  }
};
export const resetPasswordAdmin = (email: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.RESET_PASSWORD_REQUEST });
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.RESET_PASSWORD_ADMIN}`, email);
    dispatch({
      type: IAuthActionTypes.RESET_PASSWORD,
    });
    if (data?.status === true) {
      history.push(PATH_NAME.ROOT);
      toast.success('Mail sent succesfully');
    } else {
      if (data?.message) {
        toast.error(data?.message);
        return;
      }
      toast.error('Some Error Occured');
    }
  } catch (error: any) {
    if (error?.response?.data?.message[0]) {
      toast.error(error.response.data.message[0]);
      return;
    }
    toast.error('Invalid email id');
  }
};

export const updateUserProfile = (formData: any, fetchUserProfile: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE });
  try {
    const { data } = await httpRequest.patchReq(`${process.env.REACT_APP_ENDPOINT_URL}/user/${formData.id}`, formData);
    if (data?.status === true) {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
      AlertEmpty({
        title: 'Updated Successfully',
        message: 'Your profile has been updated.',
        button: 'OKAY',
      });
      fetchUserProfile();
    } else {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
      });
      AlertEmpty({
        title: data.message,
        message: 'Please try again later',
        button: 'OKAY',
      });
    }
  } catch (error) {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
    });
    console.error('Error updating user profile:', error);
  }
};

export const updateUserData = (formData: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE });
  try {
    const { data } = await httpRequest.getReq(`${process.env.REACT_APP_ENDPOINT_URL}/customers/user/${formData}`);
    if (data?.status === true) {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
      });
    }
  } catch (error) {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
    });
    console.error('Error updating user profile:', error);
  }
};

export const updatePassword = (body: string, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.UPDATE_PASSWORD_REQUEST });
  const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.UPDATE_PASSWORD}`, body);
  dispatch({
    type: IAuthActionTypes.UPDATE_PASSWORD,
  });
  if (data?.status === true) {
    history.push(PATH_NAME.LOGIN);
    console.log('YOUR PASSWORD HAS BEEN RESET!');
  } else {
    console.log('YOUR PASSWORD HAS BEEN RESET!');
  }
};

export const logout = () => (dispatch: Dispatch<any>) => {
  authService.logOut();
  dispatch({ type: IAuthActionTypes.LOGOUT });
  dispatch({ type: IAppActionTypes.LOGOUT });
  handleScrollToTop();
  AlertEmpty({ title: 'Successfully logged out', message: 'You are now logged out of your account.', button: 'Okay' });
};
export const sessionExpired = () => (dispatch: Dispatch<any>) => {
  authService.logOut();
  dispatch({ type: IAuthActionTypes.LOGOUT });
  dispatch({ type: IAppActionTypes.LOGOUT });
  handleScrollToTop();
  AlertEmpty({ title: 'Session Expired', message: 'Your session has expired. Please log in again.', button: 'Okay' });
};

export const setUserData =
  (user: string, role: string, code: string) =>
  (
    dispatch: Dispatch<SetUserDataAction>, // Type dispatch with SetUserDataAction
  ) => {
    dispatch({
      type: IAuthActionTypes.SILENT_LOGIN,
      payload: { user, role, code },
    });
  };
export const handleSignupModal = (open: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.HANDLE_SIGNUP_MODAL, payload: { open: open } });
  if (open) {
    dispatch({ type: IAuthActionTypes.HANDLE_LOGIN_MODAL, payload: { open: false } });
  }
};

export const handleLoginModal = (open: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.HANDLE_LOGIN_MODAL, payload: { open: open } });
  if (open) {
    dispatch({ type: IAuthActionTypes.HANDLE_SIGNUP_MODAL, payload: { open: false } });
  }
};

export const createAccount = (data: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.LOGIN_REQUEST });
  try {
    const response = await httpRequest.postReq(`${process.env.REACT_APP_ENDPOINT_URL}/user`, data);
    if (response.data.status) {
      Swal.fire({
        title: response.data.message,
        text: 'Please check your email for Login Credentials',
        icon: 'success',
        showCloseButton: true,
        customClass: {
          popup: 'popup-class',
          cancelButton: 'cancel-button-class',
          htmlContainer: 'html-class',
          title: 'title-class',
          confirmButton: 'confirm-button-class',
          closeButton: 'close-button-class',
        },
      });

      try {
        const role = response.data.role;
        await authService.loginWithCustomerUserAuth0(response.data.data[0].accessToken, role);
        dispatch({ type: IAuthActionTypes.LOGIN_SUCCESS });
        dispatch(handleSignupModal(false));
        history.push(PATH_NAME.ROOT);
      } catch {
        console.log('Login Failed');
      }
    } else {
      dispatch({
        type: IAuthActionTypes.LOGIN_FAILURE,
      });
      if (response.data.message) {
        AlertEmpty({
          title: 'Signup Failed',
          message: response.data.message,
          button: 'OKAY',
        });
      } else {
        AlertEmpty({
          title: 'Signup Failed',
          message: 'Failed to signin',
          button: 'OKAY',
        });
      }
    }
  } catch (error: any) {
    dispatch({
      type: IAuthActionTypes.LOGIN_FAILURE,
    });
    if (error?.reponse?.data?.message) {
      AlertEmpty({ title: 'Signup Failed', message: error.response.data.message, button: 'Okay' });
    } else {
      AlertEmpty({ title: 'Signup Failed', message: 'Failed to Create Account', button: 'Okay' });
    }
    authService.logOut();
    dispatch({ type: IAuthActionTypes.LOGOUT });
    handleScrollToTop();
  }
};

export const handleCartList = (open: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_CART_OPEN, payload: { open: open } });
};
export const setProfile = (user: any) => (dispatch: Dispatch<any>) => {
  dispatch({ type: INewUserActionTypes.SET_USER_PROFILE, payload: user });
};

export const addToCart = (product: any, openDrawer: any) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_ADD_TO_CART_SUCCESS, payload: { product, openDrawer } });
};
export const addToCartSilent = (product: any) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_ADD_TO_CART_SUCCESS_SILENT, payload: product });
};

export const removeFromCart = (unique_id: any) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART });
  dispatch({
    type: IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART_SUCCESS,
    payload: unique_id,
  });
};

export const clearAllCartItem = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: IAuthActionTypes.SET_REQUEST_CLEAR_CART_SUCCESS,
  });
};
