// api.js
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from 'services/authService';

export const API_ENDPOINT = process.env.REACT_APP_ENDPOINT_URL;

function getAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken;
}

const api = axios.create({
  baseURL: API_ENDPOINT,
});

api.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  config.headers['Content-Type'] = 'application/json';

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // check for a valid token if not then logout
    if (error.response.status === 401) {
      authService.logOut();
      window.location.href = '/';
      return error.response;
    }

    return Promise.reject(error.response);
  },
);

export default api;
