import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IMAGES_PATH } from 'configs/imagesPath';
import { DashboardIcon, MangeCustomerIcon, LogoutIcon } from 'configs/icons';
import { LogogWhite } from 'configs/logo';
import { PATH_NAME } from 'configs/pathName';
import { logout } from 'redux/actions/auth.action';

import './styles.scss';
import { ConfirmationNumberOutlined, Logout, PinOutlined, RestaurantOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import GenericLoader from 'components/GenericLoader';
import { setLoading } from 'redux/actions/app.action';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const dashboardMenu = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    routeLink: PATH_NAME.DASHBOARD,
    children: [],
  },
  // {
  //   label: 'Reports',
  //   icon: <ReportsIcon color="#fff" />,
  //   routeLink: '/dashboard',
  //   children: [],
  // },
  {
    label: 'Manage Customers',
    icon: <MangeCustomerIcon />,
    routeLink: PATH_NAME.MANAGE_CUSTOMERS,
    children: [],
  },
  {
    label: 'Manage Orders',
    icon: <ShoppingCartOutlined style={{ color: 'white' }} />,
    routeLink: PATH_NAME.MANAGE_ORDERS,
    children: [],
  },
  {
    label: 'Registration Codes',
    icon: <PinOutlined style={{ color: 'white' }} />,
    routeLink: PATH_NAME.MANAGE_CODES,
    children: [],
  },
  {
    label: 'Redemption Codes',
    icon: <ConfirmationNumberOutlined style={{ color: 'white' }} />,
    routeLink: PATH_NAME.REDEMPTION_CODE,
    children: [],
  },
  {
    label: 'Dining Codes',
    icon: <RestaurantOutlined style={{ color: 'white' }} />,
    routeLink: PATH_NAME.DINING_CODE,
    children: [],
  },
];

const AppBar: any = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function DashboardLayouts({ children, isAdmin }: any) {
  const [selectedMenuItem, setSelectedMenuItem] = React.useState('');
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUrl = history.location.pathname;
  const [open, setOpen] = React.useState(true);
  const [expandedMenu, setExpandedMenu] = React.useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenuExpand = (name: any) => {
    if (name === expandedMenu) {
      setExpandedMenu(null);
      return;
    }
    setExpandedMenu(name);
  };
  const decideExpandedIcon = (menu: any) => {
    if (!menu?.children?.length) {
      return false;
    }
    if (expandedMenu === menu.label) {
      return <ExpandLess />;
    } else {
      return <ExpandMore />;
    }
  };
  useEffect(() => {
    const menu = dashboardMenu.find((item) => item.routeLink == currentUrl);
    if (menu) {
      setSelectedMenuItem(menu.label);
    }
  }, [currentUrl]);

  return (
    <div className="dashboard">
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className="appBar">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <img src={IMAGES_PATH.icons.togglerDark} alt="togglerDark" />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
            <div style={{ cursor: 'pointer' }} onClick={() => dispatch(logout())} className="user-icon">
              <Logout />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className="sidebar-nav"
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: '#0C4CCF',
              color: 'white',
            },
          }}
        >
          <DrawerHeader>
            <div className="text-center">
              <LogogWhite />
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon color="info" className="text-white" />
              ) : (
                <ChevronLeftIcon className="text-white" />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {dashboardMenu.map((item, index) => {
              return (
                <>
                  <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <Link
                      to={item?.children?.length ? '#' : item.routeLink}
                      onClick={() => {
                        handleMenuExpand(item.label);
                        setSelectedMenuItem(item.label);
                      }}
                      className={selectedMenuItem === item.label ? 'active' : ''}
                      style={{
                        textDecoration: 'none', // Remove underline style
                        color: 'inherit', // Inherit the color from the parent
                        display: 'block',
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                          py: 2,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                        {decideExpandedIcon(item)}
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  {item.label === expandedMenu &&
                    item?.children?.map((subMenu: any, index: number) => (
                      <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <Collapse key={index} in={true} timeout="auto" unmountOnExit>
                          <Link
                            to={subMenu.routeLink}
                            onClick={() => {
                              handleMenuExpand(item.label);
                              setSelectedMenuItem(item.label);
                            }}
                            className={selectedMenuItem === item.label ? 'active' : ''}
                            style={{
                              textDecoration: 'none', // Remove underline style
                              color: 'inherit', // Inherit the color from the parent
                              display: 'block',
                            }}
                          >
                            <ListItemButton className="sidenavsubMenu" sx={{ pl: 4 }}>
                              <ListItemIcon>{subMenu.icon}</ListItemIcon>
                              <ListItemText primary={subMenu.label} />
                            </ListItemButton>
                          </Link>
                        </Collapse>
                      </ListItem>
                    ))}
                </>
              );
            })}
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => dispatch(logout())}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </div>
  );
}
export default DashboardLayouts;
